import React, { useState, useEffect } from 'react'
import AddToCartModal from '@components/product/product-parts/add-to-cart-modal'
import giftCardImage from '@assets/images/gift-cards.jpg'
import './gift-card-purchase.sass'
import { getGiftCardProductData } from '@helpers/product'
import { setupAnalytics } from '@helpers/google-tag-manager'
import GiftCardAccordions from '../accordions'

const giftcardProductData = getGiftCardProductData()

const GiftCards = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const showModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    if (modalOpen) {
      setModalOpen(false)
    }
  }

  useEffect(() => {
    setupAnalytics({ pageData: { type: 'gift-card', title: 'Gift Card Purchase', path: '/gift-card/purchase' } })
  }, [])

  return (
    <div className="gc-page card">
      <div>
        <h1 className="gc-header ">ROOMS TO GO GIFT CARDS</h1>
      </div>
      <div className="gc-wrapper grid-x">
        <div className="gc-content-wrapper grid-x small-12 large-4">
          <div className="gift-card-tile grid-x small-12 large-6 card pop-out">
            <div className="small-12 gc-image">
              <img src={giftCardImage} alt="gift card" />
            </div>
            <div className="gc-add-to-cart-wrapper small-12">
              <span className="gc-label">$100 Gift Card</span>
              <button type="button" className="blue-action-btn" onClick={showModal}>
                Add to cart
              </button>
            </div>
          </div>
        </div>
        <div className="small-12 large-7 grid-x cell gc-right">
          <p>
            The Rooms To Go gift card is an ideal gift choice for weddings, graduations, anniversaries and so many other
            special occasions.
          </p>
          <p>You will receive your Gift Card via certified mail, with no shipping or handling cost to you!</p>
          <p>
            NOTE: For security purposes, Gift Cards will only be mailed to purchaser's billing address in the Contiguous
            U.S.
          </p>
          <div className="small-12">
            <GiftCardAccordions />
          </div>
        </div>
        {modalOpen && (
          <AddToCartModal
            modalOpen={modalOpen}
            product={giftcardProductData}
            price={giftcardProductData.price}
            closeModal={closeModal}
            index={0}
          />
        )}
      </div>
    </div>
  )
}

export default GiftCards
