import React, { useEffect, useState } from 'react'
import { currencyFormatUS } from '@helpers/string-helper'
import loaderLight from '@images/loader-light.svg'
import { setupAnalytics } from '@helpers/google-tag-manager'
import useHasWindow from '@helpers/hooks/useWindow'

import Recaptcha from 'react-recaptcha'
import './balance.sass'
import { getRegionZone } from '@helpers/geo-location'
import RedeemMessage from './redeem-message'
import { submitGC, verifyRecaptchaResponse } from './balance-helper'

const SITE_KEY = '6LfHmagaAAAAALbJQjTq_LRqpkzQlNVjhQED-tx8'

const GiftCardBalance = () => {
  const numberLength = 16
  const pinLength = 8
  const [verifiedRecaptcha, setVerifiedRecaptcha] = useState(false)
  const isWindow = useHasWindow()
  const [loading, setLoading] = useState(false)
  const [err, setError] = useState(null)
  const [number, setNumber] = useState('')
  const [pin, setPin] = useState('')
  const [balance, setBalance] = useState(null)
  const regionInPR = getRegionZone().region === 'PR'

  useEffect(
    () => setupAnalytics({ pageData: { type: 'gift-card', title: 'Gift Card Balance', path: '/gift-card/balance' } }),
    [],
  )

  const verifyCallback = async token => {
    const verified = await verifyRecaptchaResponse(token)
    setVerifiedRecaptcha(verified?.success)
  }

  return (
    <div className="gc-balance cell">
      <div className="grid-x">
        <div className="cell small-12 grid-x">
          <div className="grid-x small-12 large-6 form-area">
            <div className="small-12">
              <p>Fill in the fields below to retrieve your gift card balance.</p>
              <p className="asterisk">*Asterisks indicate required fields</p>
            </div>
            <form className="grid-x cell small-12">
              {err && <span className="error">{err}</span>}
              <label className="cell small-12" htmlFor="gift_card_number">
                {numberLength}-digit Card Number
              </label>
              <input
                className="cell small-12"
                type="text"
                name="gift_card_number"
                maxLength={numberLength}
                id="gift_card_number"
                value={number}
                onChange={e => setNumber(e.target.value)}
              />
              <label className="cell small-12" htmlFor="gift_card_pin">
                {pinLength}-digit PIN Number
              </label>
              <input
                className="cell small-12"
                type="text"
                maxLength={pinLength}
                name="gift_card_pin"
                id="gift_card_pin"
                value={pin}
                onChange={e => setPin(e.target.value)}
              />
              {isWindow && !regionInPR && (
                <Recaptcha sitekey={SITE_KEY} render="explicit" verifyCallback={verifyCallback} />
              )}
              <button
                disabled={(!regionInPR && !verifiedRecaptcha) || number.length < numberLength || pin.length < pinLength}
                type="button"
                className="primary"
                gtm-category="gift-card"
                gtm-action="gift card balance check"
                gtm-label="gift-card-balance"
                onClick={e => !loading && submitGC(e, number, pin, setError, setLoading, setBalance)}
              >
                {!loading ? (
                  'Check Card Balance'
                ) : (
                  <img className="loader" alt="loading gift card balance" src={loaderLight} />
                )}
              </button>
              {!err && balance && Number(balance) > 0 && (
                <p className="balance">
                  Your card has a balance of <span>{currencyFormatUS(balance)}</span>!
                </p>
              )}
              {!err && balance && Number(balance) <= 0 && (
                <p className="balance zero">
                  Your card has a balance of <span>{currencyFormatUS(balance)}</span>.
                </p>
              )}
            </form>
          </div>
          <div className="cell grid-x small-12 large-6 numbers-image">
            <img
              className="small-12"
              alt="Gift Card Number Locations"
              src={`${process.env.GATSBY_S3_IMAGE_URL}giftcard-diagram.jpg`}
            />
            <RedeemMessage />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GiftCardBalance
